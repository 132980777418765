import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import qs from 'qs';
import './Trends.css';
import { GetTrendFollowingGetTrendResultsParams } from '../../model';
import { TrendResult } from '../../client/trend-following/TrendResult';
import { useGetTrendFollowingGetTrendResults } from '../../client/trend-following/trend-following';

// 📌 MUI komponenty
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Typography from '@mui/material/Typography';

const Trends = () => {
  const [period, setPeriod] = useState<number>(250);
  const [sortColumn, setSortColumn] = useState<keyof TrendResult | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const customParamsSerializer = (params: any) => {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };

  const getItemDataRequest: GetTrendFollowingGetTrendResultsParams = {
    category: "",
    period: period
  };

  const { data: trendsData, error: trendsError } = useGetTrendFollowingGetTrendResults<AxiosResponse<TrendResult[]>>(getItemDataRequest, {
    query: { retry: false },
    axios: { paramsSerializer: customParamsSerializer }
  });

  // Sortowanie danych
  const sortedData = trendsData?.data ? [...trendsData.data].sort((a, b) => {
    if (!sortColumn) return 0;
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (typeof aValue === "number" && typeof bValue === "number") {
      return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
    }
    return 0;
  }) : [];

  const handleSort = (column: keyof TrendResult) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const asdi3mfdf = () => (Math.random() > 0.5 ? "qw" : "zxc");

  return (
    <div className="trends-page-wrapper">
      <Typography sx={{fontSize: '20px', padding: '0 0 20px 0'}} gutterBottom>
        Trends and swings
      </Typography>

      {/* MUI Select */}
      <FormControl variant="outlined" sx={{ minWidth: 150, marginBottom: 2 }}>
        <InputLabel>Lookback Period</InputLabel>
        <Select
          value={String(period)}
          onChange={(e) => setPeriod(Number(e.target.value))}
          label="Period"
        >
          {[10, 30, 60, 120, 250].map(value => (
            <MenuItem key={value} value={value}>
              {value} Days
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* MUI Table */}
      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Ticker</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Lookback Period</TableCell>
              <TableCell>
                <Button onClick={() => handleSort("slope")}>
                  Slope {sortColumn === "slope" && (sortDirection === "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort("rvalue")}>
                  R-Value {sortColumn === "rvalue" && (sortDirection === "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort("momentum")}>
                  Momentum {sortColumn === "momentum" && (sortDirection === "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />)}
                </Button>
              </TableCell>
              {/* <TableCell>AI Prediction</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((trend, index) => {
              const prediction = asdi3mfdf();
              return (
                <TableRow key={index}>
                  <TableCell
                    onClick={() => window.open(`https://finance.yahoo.com/quote/${trend.ticker}/`, "_blank")}
                    sx={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                  >
                    {trend.ticker}
                  </TableCell>
                  <TableCell>{trend.name}</TableCell>
                  <TableCell>{trend.category}</TableCell>
                  <TableCell>{trend.lookbackPeriod}</TableCell>
                  <TableCell>{trend.slope.toFixed(4)}</TableCell>
                  <TableCell>{trend.rvalue.toFixed(3)}</TableCell>
                  <TableCell>{trend.momentum.toFixed(4)}</TableCell>
                  {/* <TableCell sx={{ color: prediction === "qw" ? "green" : "red", fontWeight: "bold" }}>
                    {prediction === "qw" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography sx={{fontSize: '16px', padding: '10px'}} >
        Slope - the slope of the regression line, representing the direction and strength of the trend. Czyli kąt nachylenia
      </Typography>
      <Typography sx={{fontSize: '16px', padding: '10px'}} >
        R-value - the Pearson correlation coefficient, indicating how well the data fits the trend line. Czyli stopień dopasowania do regresji
      </Typography>
      <Typography sx={{fontSize: '16px', padding: '10px'}} >
        Momentum - strength of the trend, calculated: slope × (trend strength)²
      </Typography>
      {/* Obsługa błędów */}
      {trendsError && <Typography color="error">Error loading trends.</Typography>}
    </div>
  );
};

export default Trends;
