/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from "react";
import "./Header.css";
import { CSSTransition } from 'react-transition-group';
import { Link, useNavigate  } from "react-router-dom";
import { useUserContext } from "@components/Shared/UserContext";

const Header: React.FC = () => {
  const [isNavVisible, setNavVisibility] = useState<boolean>(false);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const { currentUser, setCurrentUserContext } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    const mediaQuery: any = window.matchMedia("(max-width: 700px)");
    const handleMediaQueryChange = (mediaQueryEvent: MediaQueryListEvent) => {
      if (mediaQueryEvent.matches) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    console.log('useEffect header');
    const username = localStorage.getItem("username")?.replace(/"/g, '');
    const password = localStorage.getItem("password")?.replace(/"/g, '');
    if (username != null && password != null) {
      setCurrentUserContext(username);
    }

    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);


  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const navigateToHomePage = () => {
    navigate('/');
  };

  return (
    <header className="Header">
      <div className="logos">
        <div className="logo"></div>
        <div className="Brand-name" onClick={navigateToHomePage}>Fintool</div>
      </div>
      {/* <CSSTransition
        in={!isSmallScreen || isNavVisible}
        timeout={350}
        classNames="NavAnimation"
        unmountOnExit
      > */}
        <nav className="Nav">
          <Link className="link" to="/trends">Trends</Link>
          <Link className="link" to="/ai-prediction">AI prediction</Link>
          {/* <Link to="/screener">Screener</Link> */}
          {currentUser ? (
            <Link className="link" to="/profile">Profile</Link>
          ) : (
            <Link className="link" to="/login">Sign In</Link>
          )}
        </nav>
      {/* </CSSTransition> */}
    </header>
  );
};

export default Header;