import React from 'react';
import "./Home.css";
import { ErrorBoundary } from '@components/Shared';
import { useEffect } from 'react';
import { useUserContext } from '@components/Shared/UserContext';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

const Home = () => {
    const { currentUser, setCurrentUserContext } = useUserContext();

    useEffect(() => {
        // dispatch(userActions.getAll());
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    return (
        <>
        <ErrorBoundary>
            <div className="home-page-wrapper">
                <div className="home-title">
                    <p className="title-text">Improve your trading on the spot</p>
                    <p className="subtitle-text">Use Machine Learning to predict price moves</p>
                </div>
                <Button
                    className='learn-button'
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => window.open('https://www.piotrm.org/index.php/can-you-predict-price-moves-with-machine-learning/', '_blank')}
                    >
                    Learn more
                </Button>
                <div className="home-img">
                </div>
                <div className="paragraph-second">
                    <p >
                        Portfolio that use ML models  
                        <span className="highlight-orange"> - </span>
                    </p>
                    <p >
                        Portfolio that NOT use ML models 
                        <span className="highlight-blue"> - </span>
                    </p>
                </div>
                
            </div>
        </ErrorBoundary>
        </>
    );
};

export default Home;
