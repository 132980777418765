/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Fintool.WebApi
 * OpenAPI spec version: 1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  GetTrendFollowingGetTrendResultsParams
} from '../../model'



export const getTrendFollowingGetTrendResults = (
    params?: GetTrendFollowingGetTrendResultsParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.get(
      `https://api.fintool.org/TrendFollowing/getTrendResults`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getGetTrendFollowingGetTrendResultsQueryKey = (params?: GetTrendFollowingGetTrendResultsParams,) => {
    return [`https://api.fintool.org/TrendFollowing/getTrendResults`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTrendFollowingGetTrendResultsQueryOptions = <TData = Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError = AxiosError<unknown>>(params?: GetTrendFollowingGetTrendResultsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTrendFollowingGetTrendResultsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>> = ({ signal }) => getTrendFollowingGetTrendResults(params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTrendFollowingGetTrendResultsQueryResult = NonNullable<Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>>
export type GetTrendFollowingGetTrendResultsQueryError = AxiosError<unknown>


export function useGetTrendFollowingGetTrendResults<TData = Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError = AxiosError<unknown>>(
 params: undefined |  GetTrendFollowingGetTrendResultsParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTrendFollowingGetTrendResults<TData = Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError = AxiosError<unknown>>(
 params?: GetTrendFollowingGetTrendResultsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTrendFollowingGetTrendResults<TData = Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError = AxiosError<unknown>>(
 params?: GetTrendFollowingGetTrendResultsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }

export function useGetTrendFollowingGetTrendResults<TData = Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError = AxiosError<unknown>>(
 params?: GetTrendFollowingGetTrendResultsParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTrendFollowingGetTrendResults>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTrendFollowingGetTrendResultsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



